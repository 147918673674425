import API, { connectionToSideArmApi } from '@/services/api/api';
import {
  PlayerDetails,
  RosterPlayer,
  Coach,
  CoachDetails,
  ScheduledGames,
  PublicationSettings,
  Opponent,
  Sport,
  publicationPage,
} from '@/store/client/types';
import axios from 'axios';
import { DateTime } from 'luxon';

interface PagedRecords<T> {
  items: T[];
  total: number;
  page: number;
  pages: number;
}

interface CursorPagedRecords<T> {
  items: T[];
  after: string;
  before: string;
}

const fetchClient = async (clientId: number) => {
  try {
    const response = await API.get(`clients/${clientId}`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchSports = async (params: { clientId: number }) => {
  const clientId = params.clientId;
  if (!clientId) return null;
  try {
    const response = await API.get(`clients/${clientId}/sports`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchSport = async (params: { clientId: number; clientSportId: number }): Promise<Sport> => {
  const { clientId, clientSportId } = params;
  if (clientSportId == 0) return null;
  try {
    const response = await API.get(`clients/${clientId}/sports/${clientSportId}`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

interface FetchStoriesParams {
  clientId: number;
  page?: number;
  perPage?: number;
  sportId?: Nullable<number>;
  search?: string;
}

const fetchStories = async (params: FetchStoriesParams) => {
  const { clientId, page, perPage, sportId, search } = params;
  try {
    const response = await API.get(`clients/${clientId}/stories`, {
      params: { PageIndex: page, PageSize: perPage, sportId, search },
    });
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchStory = async (params: { clientId: number; storyId: string }) => {
  const { clientId, storyId } = params;
  try {
    const response = await API.get(`clients/${clientId}/stories/${storyId}`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchSportEvents = async (params: { clientId: number; sportId: number }) => {
  const clientId = params.clientId;
  const sportId = params.sportId;
  if (!clientId || !sportId) return null;
  try {
    const response = await API.get(`clients/${clientId}/sports/${sportId}/events`);
    return response.data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchScheduleResultsEvents = async (params: { clientId: number; clientSportId: number }) => {
  const { clientId, clientSportId } = params;
  try {
    const { data } = await API.get<CursorPagedRecords<RosterPlayer>>(
      `clients/${clientId}/sports/${clientSportId}/eventresults`,
      {
        params: { PageSize: 9999 },
      }
    );
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchScheduleUpcomingEvents = async (params: { clientId: number; clientSportId: number }) => {
  const { clientId, clientSportId } = params;
  try {
    const { data } = await API.get<CursorPagedRecords<RosterPlayer>>(
      `clients/${clientId}/sports/${clientSportId}/upcomingevents`,
      {
        params: { PageSize: 9999 },
      }
    );
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchRosterPlayers = async (params: { clientId: number; rosterId: number }): Promise<RosterPlayer[]> => {
  const { clientId, rosterId } = params;
  try {
    const { data } = await API.get<PagedRecords<RosterPlayer>>(`clients/${clientId}/rosters/${rosterId}/players`, {
      params: { PageSize: 9999 },
    });
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchPlayers = async (params: {
  clientId: number;
  playersIds: number[];
}): Promise<Record<number, PlayerDetails>> => {
  const { clientId, playersIds } = params;
  const query = playersIds.map((id) => `playerId=${id}`).join('&');
  try {
    const { data } = await API.get<Record<number, PlayerDetails>>(`clients/${clientId}/players?${query}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchSportCoaches = async (params: { clientId: number; clientSportId: number }): Promise<CoachDetails[]> => {
  const { clientId, clientSportId } = params;
  try {
    const { data } = await API.get<PagedRecords<CoachDetails>>(`clients/${clientId}/sports/${clientSportId}/coaches`, {
      params: { PageSize: 9999 },
    });
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchCoach = async (params: { clientId: number; coachId: number }): Promise<CoachDetails> => {
  const { clientId, coachId } = params;
  try {
    const { data } = await API.get<CoachDetails>(`clients/${clientId}/coaches/${coachId}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchCoaches = async (params: { clientId: number; clientSportId: number }): Promise<Coach[]> => {
  const { clientId, clientSportId } = params;
  try {
    const { data } = await API.get<PagedRecords<Coach>>(`clients/${clientId}/sports/${clientSportId}/coaches`, {
      params: { PageSize: 9999 },
    });
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchCoachesMembers = async (params: {
  clientId: number;
  membersIds: number[];
}): Promise<Record<number, CoachDetails>> => {
  const { clientId, membersIds } = params;
  const query = membersIds.map((id) => `coachesId=${id}`).join('&');
  try {
    const { data } = await API.get<Record<number, CoachDetails>>(`clients/${clientId}/coaches?${query}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchScheduleEventInfo = async (params: { eventId: number; tenantId: string }): Promise<ScheduledGames> => {
  const { tenantId, eventId } = params;
  if (eventId == 0) return null;
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get<ScheduledGames>(`/api/v2/ScheduleGames/${eventId}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchOpponentInfo = async (params: { opponentId: number; tenantId: string }): Promise<Opponent> => {
  const { tenantId, opponentId } = params;
  if (opponentId == 0) return null;
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get(`/api/v2/odata/ScheduleOpponents?filter=opponentId%20eq%20${opponentId}`);
    return data.items[0];
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchGlobalSport = async (params: { globalSportId: number; tenantId: string }) => {
  const { tenantId, globalSportId } = params;
  const API2 = connectionToSideArmApi(tenantId);

  try {
    const { data } = await API2.get(`/api/v2/Sports/global/${globalSportId}`);

    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchOpponentRosterPlayers = async (params: { rosterId: number; tenantId: string }) => {
  const { tenantId, rosterId } = params;
  const API2 = connectionToSideArmApi(tenantId);

  try {
    const { data } = await API2.get(`/api/v2/Players/roster/${rosterId}`, {
      params: { $pageSize: 9999 },
    });
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchOpponentRosterBio = async (params: { rosterPlayerId: number; tenantId: string }) => {
  const { tenantId, rosterPlayerId } = params;

  const API2 = connectionToSideArmApi(tenantId);

  try {
    const { data } = await API2.get(`/api/v2/Players/roster/bio/${rosterPlayerId}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchOpponentCoaches = async (params: { sportId: number; tenantId: string }): Promise<Coach[]> => {
  const { tenantId, sportId } = params;
  const API2 = connectionToSideArmApi(tenantId);

  try {
    const { data } = await API2.get(`/api/v2/Staff/coaches/?onlyHeadCoach=false&sportId=${sportId}&$pageSize=999`);
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};
const fetchOpponentCoachBio = async (params: { coachId: number; tenantId: string }) => {
  const { tenantId, coachId } = params;
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get(`/api/v2/Staff/coaches/${coachId}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchOpponentScheduleResultEvents = async (params: { sportId: number; tenantId: string }) => {
  const { tenantId, sportId } = params;
  const API2 = connectionToSideArmApi(tenantId);

  try {
    const { data } = await API2.get(`/api/v2.1/EventsResults/results/schedule?sportId=${sportId}&pageSize=9999`);
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchOpponentScheduleUpcomingEvents = async (params: { sportId: number; tenantId: string }) => {
  const { tenantId, sportId } = params;
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get(`/api/v2.1/EventsResults/upcoming/?sportId=${sportId}&pageSize=9999`);
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const getOpponentTenantId = async (opponentGlobalId: string) => {
  const API2 = axios.create({ baseURL: 'https://services.sidearmsports.com/services' });
  try {
    const { data } = await API2.get(`/globalopponentlookup.ashx?client_school_id=${opponentGlobalId}`);
    return data.schools[0];
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

// const getSupportTenantId = async (opponentGlobalId: string) => {
//   const API2 = axios.create({ baseURL: 'https://services.sidearmsports.com/services' });
//   try {
//     const { data } = await API2.get(`/globalopponentlookup.ashx?client_school_id=${opponentGlobalId}`);
//     return data.schools[0];
//   } catch (e) {
//     return e.response ? Promise.reject(e.response) : e;
//   }
// };

const fetchSupportTeam = async (params: { sportId: number; tenantId: string }): Promise<Coach[]> => {
  const { tenantId, sportId } = params;
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get<PagedRecords<Coach>>(`/api/v2/Staff/supportStaff?sportId=${sportId}`, {
      params: { $pageSize: 9999 },
    });
    return data.items;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchPublicationSettings = async (tenantId: string): Promise<PublicationSettings> => {
  const API2 = connectionToSideArmApi(tenantId);
  try {
    const { data } = await API2.get('/api/v2/Publications/settings');
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

const fetchPublicationPage = async (s3BucketUri: string): Promise<publicationPage> => {
  const connectionToPublicationFile = axios.create({
    baseURL: `${s3BucketUri}`,
  });
  try {
    const timestamp = DateTime.now().toFormat('yyyyMMddHHmmss');
    const { data } = await connectionToPublicationFile.get(`/data_live.json?v=${timestamp}`);
    return data;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

export default {
  fetchClient,
  fetchSports,
  fetchSport,
  fetchStories,
  fetchStory,
  fetchSportEvents,
  fetchScheduleResultsEvents,
  fetchScheduleUpcomingEvents,
  fetchRosterPlayers,
  fetchPlayers,
  fetchSportCoaches,
  fetchCoach,
  fetchCoaches,
  fetchCoachesMembers,
  fetchScheduleEventInfo,
  fetchOpponentInfo,
  fetchGlobalSport,
  fetchOpponentScheduleResultEvents,
  fetchOpponentScheduleUpcomingEvents,
  fetchOpponentRosterPlayers,
  fetchOpponentRosterBio,
  fetchOpponentCoaches,
  fetchOpponentCoachBio,
  getOpponentTenantId,
  fetchSupportTeam,
  fetchPublicationSettings,
  fetchPublicationPage,
};
